<template>
    <div class="videoVaultPage">
<!--        <HeadBar titleName="片库"></HeadBar>-->
        <div class="headBar">
          <div class="leftIcon" @click="$router.go(-1)">
            <svg-icon class="navArrow" iconClass="navArrow" />
          </div>
          <div class="headTitle">
            <span>片库</span>
          </div>
          <div class="rightBtn" @click="rightBtn">
            <span>重置</span>
          </div>
        </div>
<!--        <PullRefresh v-if="!isLoading" :loading="loading" :refreshing="refreshing" :finished="finished" @onLoad="onLoad"-->
<!--            @onRefresh="onRefresh" :error="error" :is-higeht-max="true" :adv-list="advList" :hasAdvPagination="true"-->
<!--            className="TopicPage">-->

            <div class="content oepnX">
             <div class="showTab" v-if="typeTags">
                <div class="labelTitle" v-show="showLabelTitle">
                 <span>{{this.typeTags['canvas'][this.canvasIndex]['name']}}·{{this.typeTags['orderBy'][this.orderByIndex]['name']}}·{{this.typeTags['tags'][this.tagsByIndex]['name']}}·{{this.typeTags['paymentType'][this.paymentTypeByIndex]['name']}}·{{this.typeTags['timeType'][this.timeTypeByIndex]['name']}}</span>
                </div>
                <div class="classify" v-show="!showLabelTitle">

                  <div class="classifyItem">
                    <div class="classifyType">
                      <span>类型</span>
                    </div>
                    <div class="classifyTypeValue">
                      <div v-for="(item,index) in typeTags.canvas" :key="index">
                        <div class="classifyLabel" @click="onCanvasTagLabel(index)"
                             :class="{classifyItemActive: canvasIndex === index}">
                          <span>{{item.name}}</span>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="classifyItem">
                    <div class="classifyType">
                      <span>排序</span>
                    </div>
                    <div class="classifyTypeValue">
                      <div v-for="(item,index) in typeTags.orderBy" :key="index">
                        <div class="classifyLabel" @click="onOrderByTagLabel(index)"
                             :class="{classifyItemActive: orderByIndex === index}">
                          <span>{{item.name}}</span>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="classifyItem">
                    <div class="classifyType">
                      <span>标签</span>
                    </div>
                    <div class="classifyTypeValue">
                      <div v-for="(item,index) in typeTags.tags" :key="index">
                        <div class="classifyLabel" @click="onTagsTagLabel(index)"
                             :class="{classifyItemActive: tagsByIndex === index}">
                          <span>{{item.name}}</span>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="classifyItem">
                    <div class="classifyType">
                      <span>类型</span>
                    </div>
                    <div class="classifyTypeValue">
                      <div v-for="(item,index) in typeTags.paymentType" :key="index">
                        <div class="classifyLabel" @click="onPaymentTypeTagLabel(index)"
                             :class="{classifyItemActive: paymentTypeByIndex === index}">
                          <span>{{item.name}}</span>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="classifyItem">
                    <div class="classifyType">
                      <span>时间</span>
                    </div>
                    <div class="classifyTypeValue">
                      <div v-for="(item,index) in typeTags.timeType" :key="index">
                        <div class="classifyLabel" @click="onTimeTypeTagLabel(index)"
                             :class="{classifyItemActive: timeTypeByIndex === index}">
                          <span>{{item.name}}</span>
                        </div>
                      </div>
                    </div>
                  </div>


<!--                    <div class="classifyItem">-->
<!--                        <div class="classifyType">-->
<!--                            <span>{{tagTypeData.name}}</span>-->
<!--                        </div>-->
<!--                        <div class="classifyTypeValue">-->
<!--                            <div v-for="(item,index) in tagTypeData.tags" :key="index">-->
<!--                                <div class="classifyLabel" @click="onTagLabel(tagTypeData,item,index)"-->
<!--                                    :class="{classifyItemActive: tagTypeData.activeIndex == index}">-->
<!--                                    <span>{{item.name}}</span>-->
<!--                                </div>-->
<!--                            </div>-->
<!--                        </div>-->
<!--                    </div>-->

<!--                    <div class="classifyItem" v-for="(item,index) in resultList" :key="index">-->
<!--                        <div class="classifyType">-->
<!--                            <span>{{item.name}}</span>-->
<!--                        </div>-->
<!--                        <div class="classifyTypeValue">-->
<!--                            <div v-for="(itemTag,indexTag) in item.tags" :key="indexTag">-->
<!--&lt;!&ndash;                                <div class="classifyLabel" v-if="!item.isAn && indexTag < 4"&ndash;&gt;-->
<!--&lt;!&ndash;                                    @click="onTagLabelCondition(item,itemTag,indexTag)"&ndash;&gt;-->
<!--&lt;!&ndash;                                    :class="{classifyItemActive:item.activeIndex == indexTag}">&ndash;&gt;-->
<!--&lt;!&ndash;                                    <span>{{itemTag.name}}</span>&ndash;&gt;-->
<!--&lt;!&ndash;                                </div>&ndash;&gt;-->
<!--                                <div class="classifyLabel"-->
<!--                                    @click="onTagLabelCondition(item,itemTag,indexTag)"-->
<!--                                    :class="{classifyItemActive:item.activeIndex == indexTag}">-->
<!--                                    <span>{{itemTag.name}}</span>-->
<!--                                </div>-->
<!--                            </div>-->
<!--&lt;!&ndash;                            <div class="unfoldBtn classifyLabel" @click="switchTag(item)">&ndash;&gt;-->
<!--&lt;!&ndash;                                <span>{{item.isAn?"收起":"展开"}}</span>&ndash;&gt;-->
<!--&lt;!&ndash;                            </div>&ndash;&gt;-->
<!--                        </div>-->
<!--                    </div>-->
                </div>
                <div class="bottom" @click="changeSticky">
                 <svg-icon class="isShow" iconClass="isShow" v-show="!showLabelTitle" />
                 <svg-icon class="isShow" iconClass="noShow" v-show="showLabelTitle" />
                </div>
             </div >
<!--                <van-sticky offset-top="1.2rem" @change="changeSticky">-->
<!--                    <div></div>-->
<!--                </van-sticky>-->
                <!-- <van-sticky class="labelTitle">
                  <div>123123123</div>
                </van-sticky> -->
                <div class="contentVideo" :style="{height: showLabelTitle?'calc(100vh - 128px)':'calc(100vh - 323px)'}" v-if="typeTags">
                    <VaultList ref="vaultList" :keyword="keyword()"></VaultList>
<!--                  <TransverselyTwoList :videoList="videoList"></TransverselyTwoList>-->
<!--                    <VerticleThreeList :videoList="videoList" v-if="isVertical"></VerticleThreeList>-->
<!--                    <TransverselyTwoList :videoList="videoList" v-else></TransverselyTwoList>-->
<!--                    &lt;!&ndash; 猜你想看 &ndash;&gt;-->
<!--                    <div v-if="videoHotList && videoHotList.length > 0" class="guess">-->
<!--                        <div class="guessTitle">-->
<!--                            <span>猜你想看</span>-->
<!--                        </div>-->
<!--                        <VerticleThreeList :videoList="videoHotList" v-if="isVertical"></VerticleThreeList>-->
<!--                        <TransverselyTwoList :videoList="videoHotList" v-else></TransverselyTwoList>-->
<!--                    </div>-->
                </div>
            </div>
<!--        </PullRefresh>-->
<!--        <Loading v-else />-->
    </div>
</template>

<script>
    import {AdType, getAdItem} from "@/utils/getConfig";
    import VerticleThreeList from "@/components/VideoList/VerticleThreeList";
    import VaultList from "./vaultList/index.vue";
    import TransverselyTwoList from "@/components/VideoList/TransverselyTwoList";
    import HeadBar from "@/components/HeadBar";
    import PullRefresh from "@/components/PullRefresh";
    import Loading from "@/components/Loading";
    import {
      queryHotkindTagsList,
      queryTagsmediaList,
      queryHotInfoList, queryLibraryList, queryLibrarySearchList
    } from "@/api/video"
    export default {
        name: "VideoVaultPage",
        components: {
            // HeadBar,
            // PullRefresh,
            // Loading,
            VaultList,
            // VerticleThreeList,
            // TransverselyTwoList
        },
        data() {
            return {
                typeTags: null,
                canvasIndex: 0,
                orderByIndex: 0,
                tagsByIndex: 0,
                paymentTypeByIndex: 0,
                timeTypeByIndex: 0,
                tagTypeData: {
                    name: "类型",
                    isAn: false,
                    activeIndex: 0,
                    parTages: null,
                    tags: [{
                            name: "长视频",
                            kind: 0
                        },
                        {
                            name: "短视频",
                            kind: 1
                        },
                    ]
                },
                resultList: [],
                pageNumber: 1,
                pageSize: 20,
                isLoading: true,
                loading: true,
                refreshing: false,
                finished: false,
                isNoData: false,
                error: false,
                advList: [],
                videoList: [],
                format: 0,
                showLabelTitle: false,
                tagTitle: null,
                labelName: "动漫",
                videoHotList: [],
                oldVideoList: [],
                listAdvs: [],
                listAdvsVerticel: [],
                isVertical: true,
                isTypeLoading : 0,   //书籍加载，如果加载完，就加载推荐（0：加载书籍数据，1：加载推荐数据）
            }
        },
        created() {
            this.listAdvs = getAdItem(AdType.CLASSIFICARION);
            this.listAdvsVerticel = getAdItem(AdType.CLASSIFICARION_VERTICLE);
            // console.log(this.listAdvs,"----------------")
            // console.log(this.listAdvsVerticel,"+++++++++++++++")
            // console.log(this.listAdvs)
        },
        mounted() {
            // this.parTages = new Set();
            // this.tagTitle = new Set();
            // this.getHotkindTagsList();
            // this.getTagsmediaList();
            this.getLibraryList();
        },
        methods: {
          keyword(){
            return {
              canvas: this.typeTags['canvas'][this.canvasIndex],
              orderBy: this.typeTags['orderBy'][this.orderByIndex],
              tags: this.typeTags['tags'][this.tagsByIndex],
              paymentType: this.typeTags['paymentType'][this.paymentTypeByIndex],
              timeType: this.typeTags['timeType'][this.timeTypeByIndex],
            };
          },
          rightBtn(){
            this.canvasIndex = 0;
            this.orderByIndex = 0;
            this.tagsByIndex = 0;
            this.paymentTypeByIndex = 0;
            this.timeTypeByIndex = 0;
            this.$nextTick(()=>{
              this.$refs.vaultList.reload();
            })
          },
          //切换类型
          onCanvasTagLabel(index){
            this.canvasIndex = index;
              this.$nextTick(()=>{
                this.$refs.vaultList.reload();
              })
          },
          //切换排序类型
          onOrderByTagLabel(index){
            this.orderByIndex = index;
            this.$nextTick(()=>{
              this.$refs.vaultList.reload();
            })
          },
          //切换标签类型
          onTagsTagLabel(index){
            this.tagsByIndex = index;
            this.$nextTick(()=>{
              this.$refs.vaultList.reload();
            })
          },
          //切换付款类型
          onPaymentTypeTagLabel(index){
            this.paymentTypeByIndex = index;
            this.$nextTick(()=>{
              this.$refs.vaultList.reload();
            })
          },
          //切换时间类型
          onTimeTypeTagLabel(index){
            this.timeTypeByIndex = index;
            this.$nextTick(()=>{
              this.$refs.vaultList.reload();
            })
          },
          //获取标签类型数据
          async getLibraryList() {
            let res = await this.$Api(queryLibraryList, {});
            if(res.code === 200) {
              this.typeTags = res.data || {};
            }
          },
            async getHotkindTagsList() {
                let req = {
                    kind: this.tagTypeData.tags[this.tagTypeData.activeIndex].kind
                }
                let res = await this.$Api(queryHotkindTagsList, req);

                if (res && res.code == 200) {
                    res.data.list.forEach((iterator) => {
                        iterator.isAn = false;
                    })
                    this.resultList = res.data.list;
                }
            },
            changeSticky() {
                this.showLabelTitle = !this.showLabelTitle;
            },
            switchTag(item) {
                item.isAn = !item.isAn;
            },
            onTagLabel(tagTypeData, item, index) {
                console.log(tagTypeData, item, index);
                tagTypeData.activeIndex = index;
                this.isVertical = item.kind != 3;
                // this.labelName = tagTypeData.tag[index].name;
                this.labelName = this.tagTypeData.tags[this.tagTypeData.activeIndex].name;
                this.parTages.clear();
                this.tagTitle.clear();
                this.videoHotList = [];
                this.isTypeLoading = 0;
                this.getHotkindTagsList();
                this.pageNumber = 1;
                this.finished = false;
                this.isNoData = false;
                this.getTagsmediaList('refresh');
            },
            onTagLabelCondition(tagTypeData, item, index) {
                this.isTypeLoading = 0;
                this.videoHotList = [];
                if (tagTypeData.activeIndex == index) {
                    tagTypeData.activeIndex = undefined;
                    this.parTages.delete(item.id)
                    this.tagTitle.delete(item.name);

                } else {

                    if (tagTypeData.activeIndex != undefined) {
                        this.parTages.delete(tagTypeData.tags[tagTypeData.activeIndex].id)
                        this.tagTitle.delete(tagTypeData.tags[tagTypeData.activeIndex].name);
                        this.parTages.add(item.id);
                        this.tagTitle.add(item.name);
                    } else {
                        this.parTages.add(item.id);
                        this.tagTitle.add(item.name);
                    }

                    tagTypeData.activeIndex = index;
                    // console.log(this.tagTitle)

                }
                // console.log(this.tagTitle)
                if (Array.from(this.tagTitle).length > 0) {
                    this.labelName = this.tagTypeData.tags[this.tagTypeData.activeIndex].name + "•" + Array.from(
                        this.tagTitle).join("•");
                } else {
                    this.labelName = this.tagTypeData.tags[this.tagTypeData.activeIndex].name;
                }
                this.$forceUpdate();
                this.pageNumber = 1;
                this.finished = false;
                this.isNoData = false;
                this.getTagsmediaList('refresh');
            },
            async getTagsmediaList(type) {
                if (type) {
                    this.isTypeLoading = 0;
                }
                let req = {
                    kind: this.tagTypeData.tags[this.tagTypeData.activeIndex].kind,
                    pageNumber: this.pageNumber,
                    pageSize: this.pageSize
                }
                if (this.parTages.size > 0) {
                    req.tags = Array.from(this.parTages)
                }

                let res = await this.$Api(queryTagsmediaList, req);

                this.loading = false;
                this.refreshing = false;
                this.isLoading = false;

                try {
                    if (res && res.code == 200) {
                        if (res.data.MediaList) {
                            if (type == "refresh") {
                                this.oldVideoList = res.data.MediaList || [];
                            } else {
                                this.oldVideoList = this.oldVideoList.concat(res.data.MediaList);
                            }

                            if (this.listAdvs.length > 0 || this.listAdvsVerticel.length > 0) {
                            // this.oldVideoList = this.oldVideoList.concat(list);
                            let advNum = Math.floor(this.oldVideoList.length / 4);
                            this.videoList = JSON.parse(JSON.stringify(this.oldVideoList));
                            let advLength = this.isVertical ? this.listAdvs.length : this.listAdvsVerticel.length;
                            for (let i = 0; i < advNum; i++) {
                                let advItem =  this.isVertical ? this.listAdvs[i % advLength] : this.listAdvsVerticel[i     % advLength];
                                this.videoList.splice(5 * i + 4, 0, {
                                    adv: advItem
                                });
                            }
                        } else {
                            this.videoList = [].concat(this.oldVideoList);
                        }
                        } else if (this.pageNumber == 1) {
                            this.videoList = []
                        }
                        if (!res.data.hasNext || res.data.MediaList.length < req.pageSize) {
                            // this.finished = true;
                            this.isTypeLoading = 1;
                            this.getHotInfoList('refresh');
                        }
                    } else {
                        this.error = true;
                    }
                } catch (error) {
                    this.error = true;
                    this.loading = false;
                }
            },
            async getHotInfoList(type) {
                /**
                 * 猜你喜欢
                 * @type {{pageNumber: number, kind: number, pageSize: number}}
                 */
                let req = {
                    pageNumber: this.pageNumber,
                    pageSize: this.pageSize,
                    kind: this.tagTypeData.tags[this.tagTypeData.activeIndex].kind,
                }
                let res = await this.$Api(queryHotInfoList, req);

                this.loading = false;
                this.refreshing = false;
                this.isLoading = false;

                if (res && res.code == 200) {

                    if (res.data.MediaList && res.data.MediaList.length > 0) {
                        if (type == "refresh") {
                            this.videoHotList = res.data.MediaList;
                        } else {
                            this.videoHotList = this.videoHotList.concat(res.data.MediaList);
                        }
                    }



                    // if (this.pageNumber === 1 && this.videoList && this.videoList.length === 0) {
                    //     this.isNoData = true;
                    // }
                    if (!res.data.hasNext || res.data.MediaList.length < req.pageSize) {
                        this.finished = true;
                    }
                }
            },
            onRefresh() {
                this.isTypeLoading = 0;
                this.pageNumber = 1;
                this.refreshing = true;
                this.finished = false;
                this.isNoData = false;
                this.loading = true;
                this.videoHotList = [];
                this.getTagsmediaList('refresh');
            },
            onLoad() {
                this.loading = true;
                if (this.error) {
                    this.error = false;
                } else {
                    this.pageNumber++
                }
                if(this.isTypeLoading == 0){
                    this.getTagsmediaList();
                }else{
                    this.getHotInfoList();
                }

            }
        }
    }
</script>

<style lang="scss" scoped>
    .videoVaultPage {
        height: 100%;

      .headBar{
        //border: solid 1px red;
        background: #FFFFFF;
        display: flex;
        height: 44px;
        align-items: center;
        // border-bottom: solid 1px rgb(43,39,74);

        .leftIcon{
          padding: 0 12px;
          .navArrow{
            width: 17px;
            height: 17px;
          }
        }

        .headTitle{
          color: #000000;
          width: 100%;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-right: 24px;
          font-weight: 500;
          font-size: 16px;
        }

        .rightBtn{
          position: absolute;
          right: 10px;
          font-size: 12px;
          font-weight: 400;
          color: #333;
        }
      }

        .content {
            height: calc(100% - 44px);
            //padding: 0 12px;
            //margin-top: 18px;
            .showTab {
             width: 100%;
             height: auto;
             margin-bottom: 12px;
             padding: 16px 0;
             background: #fff;
             position: relative;
             z-index: 9;

             .labelTitle {
              width: 100%;
              height: 28px;
              font-size: 12px;
              line-height: 42px;
              text-align: center;
              font-weight: 900;
              //display: flex;
              //align-items: center;
              //justify-content: center;
              color: #B09FD9;
              background: #fff;
              //position: fixed;
              //top: 42px;
              //z-index: 2;
              // position: fixed;
              // top: -10000px;
              // /deep/ .van-sticky--fixed {
              //     height: 42px;
              //     line-height: 42px;
              //     width: 100%;
              //     font-size: 15px;
              //     text-align: center;
              //     display: block !important;
              //     top: 45px;
              //     background: rgba(255, 255, 255, 0.81);
              // }
             }


             .bottom {
              width: 80px;
              height: 22px;
              position: absolute;
              bottom: -22px;
              left: 50%;
              transform: translateX(-50%);
              background: #fff;
              z-index: 99;
              display: flex;
              justify-content: center;
              border-radius: 0 0 20px 20px;
              .isShow {
               margin-top: 2px;
               width: 17.04px;
               height: 10.62px;
              }
             }
            }

            .classify {

                .classifyItem {
                    display: flex;
                    align-items: center;
                    font-size: 12px;
                    color: #000;
                    font-weight: 600;
                    margin-bottom: 16px;

                    .classifyType {
                      padding-left: 20px;
                      padding-right: 11px;
                        width: 40px;
                        //margin-right: 10px;
                        //margin-top: 24px;
                        //margin-left: 16px;
                        font-weight: bolder;
                    }
                    .classifyType:last-child {
                      margin-right: 0;
                    }

                    .classifyTypeValue {
                        display: flex;
                        //flex-wrap: wrap;
                        //width: calc(100% - 16px);
                        width: 100%;
                        overflow-x: auto;

                        .classifyLabel {
                           //width: 30px;
                           //height: 26px;
                           //line-height: 26px;
                            padding: 4px 14px;
                            margin-right: 14px;
                           //margin-top: 16px;
                           background: rgba(245, 245, 245, 1);
                           border-radius: 8px;
                           color: #999999;
                           font-weight: 600;
                           white-space: nowrap;
                          border: 2px solid transparent;
                        }
                        .classifyItemActive {
                         color: #B09FD9 !important;
                         //padding: 0 14px;
                         font-weight: bolder;
                         //line-height: 24px;
                         border: 2px solid rgba(0, 0, 0, 0.05);
                         background: #fff;
                         //box-sizing: border-box;
                        }
                    }

                    .unfoldBtn {
                        //margin-right: 30px;
                        margin-top: 18px;
                        font-weight: bolder;
                        color: #999999;
                    }
                }
            }

            .contentVideo {
                //padding: 0 12px;
                padding-top: 18px;
            }
        }
        .guess{

            .guessTitle{
                color: rgb(0,0,0);
                font-size: 18px;
                font-weight: bold;
                margin-bottom: 9px;
            }
        }
    }
</style>
