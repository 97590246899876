<template>
 <div class="verticleTwoList">
  <div class="videoInfo">
   <div
     class="videoInfoItem"
     v-for="(item, index) in videoList"
     :key="item + index"
   >
    <div class="advBox" v-if="item.adv" @click="goAdv(item.adv)">
     <ImgDecypt
       class="advImg"
       :key="item.adv.cover"
       :src="item.adv.cover"
     />
     <div class="advTitle">{{ item.adv.title }}</div>
    </div>
    <div class="videoItem" v-else>
     <!-- 编辑模式 -->
     <div class="videoEdit" v-if="isEdit" @click="del(item)">
      <img src="@/assets/png/videoDel.png" />
     </div>

     <div class="videoLabel" v-if="item.permission == 1">
      <!--            <svg-icon class="Golden" iconClass="Golden"></svg-icon>-->
      <!--            {{-->
      <!--              (item.kind == 4 || item.kind == 1) && item.price > 0-->
      <!--                ? item.price-->
      <!--                : ""-->
      <!--            }}-->
      <img class="icon" src="@/assets/png/goldLabelIcon.png" alt="">
      <!-- <span>金豆</span> -->
     </div>

     <div class="videoLabel" v-if="item.permission == 2">
      <img class="icon" src="@/assets/png/freeLabelIcon.png" alt="">
      <!--            <span>免费</span>-->
     </div>

     <div class="videoLabel" v-if="item.permission === 0">
      <img class="icon" src="@/assets/png/vipLabelIcon.png" alt="">
     </div>

     <div class="previewImg" @click="openDetails(videoList, item, index)">
      <ImgDecypt
        :key="item['verticalCover'] || item['horizontalCover']"
        :src="item['verticalCover'] || item['horizontalCover']"
      />
      <div class="mask" v-if="item.updateStatus || item.updateStatus == 0">
       <!--              <div-->
       <!--                class="maskText"-->
       <!--                :class="{ updateEnd: item.updateStatus == 2 }"-->
       <!--              >-->
       <!--                {{ item.updatedDesc }}-->
       <!--              </div>-->
       <div class="total">
        <span v-if="item.updateStatus == 2 || item.updateStatus == 0">已完结·{{ item.totalEpisode }}话</span>
        <span v-else>更新中·{{ item.totalEpisode }}话</span>
       </div>
      </div>
     </div>

     <div class="videoTxt">
      <p class="videoTxtTitle">{{ item.title }}</p>
      <div v-if="!item.adv && item['tagNames']">
              <span
                v-for="(itemTag, indexTag) in item['tagNames'].slice(0, 3)"
                :key="indexTag"
                class="videoTxtTag"
                :class="item.activeTagName === itemTag ? 'activeTag' : ''"
                @clicks.stop="tagJump(item, indexTag, itemTag)"
              >
                <span>
                  {{ itemTag }}
                </span>
                <br v-if="(indexTag + 1) % 3 == 0" />
              </span>
      </div>
      <div v-else-if="!item.adv && item['tagDetails']">
              <span
                v-for="(itemTag, indexTag) in item['tagDetails'].slice(0, 3)"
                :key="indexTag"
                class="videoTxtTag"
                :class="item.activeTagName === itemTag.name ? 'activeTag' : ''"
                @click.stop="tagJump(item, indexTag, itemTag.name)"
              >
                <span>
                  {{ itemTag }}
                </span>
                <br v-if="(indexTag + 1) % 3 == 0" />
              </span>
      </div>
     </div>
    </div>
   </div>
  </div>
 </div>
</template>

<script>
/**
 * 竖版视频列表（三）
 */
import ImgDecypt from "@/components/ImgDecypt";
import {jumpAdv, openDetails} from "@/utils/getConfig";
import store from "@/store/index.js";
import {queryHistoryId} from "@/utils";
export default {
 name: "VerticleTwoList",
 components: {
  ImgDecypt,
 },
 props: {
  videoList: {
   //视频列表
   type: Array,
  },
  isEdit: {
   //是否编辑模式
   type: Boolean,
   default: false,
  },
  isAcg: {
   type: Boolean,
  }
 },
 // watch: {
 //   '$route': {
 //     handler(newRoute, oldRoute) {
 //       // console.log(this.$route);
 //       if (newRoute.query.id) {
 //         if (this.isFirstIdSet) {
 //           this.isFirstIdSet = false;
 //         } else if (newRoute.path.includes('novelDetailsPage') && newRoute.query.kind) {
 //           if (newRoute.query.id && newRoute.query.id !== oldRoute.query.id) {
 //             this.$router.go(0);
 //           }
 //         }
 //       }
 //     },
 //     immediate: true, // 立即执行处理函数
 //   },
 // },
 data() {
  return {
   isShow: true,
   // isFirstIdSet: true
  };
 },
 mounted() {
  console.log(this.videoList, '1111');
 },
 methods: {
  openDetails,
  goAdv(item) {
   jumpAdv(item);
  },
  async jump(item, index) {
   let kind = item["kind"];
   let newsType = item["newsType"];

   console.log(item, kind, newsType);
   if (kind != null) {
    switch (kind) {
     case 0:
     case 2:
     case 3:
      //真人视频
      this.$router.push({
       path: "/videoDetailsPage",
       query: {
        id: item.id,
        isAcg: 0,
        kind: kind,
       },
      });
      break;
     case 6:
      //动漫视频
      this.$router.push({
       path: "/videoDetailsPage",
       query: {
        id: item.id,
        isAcg: kind === 0?1:0,
        kind: kind,
       },
      });
      break;
     case 8:
      this.$store.commit("video/SET_SHORT_VIDEO_LIST", this.videoList);
      this.$store.commit("video/UPDATE_SHORT_VIDEO_INDEX",index);
      this.$router.push({
       path: "/shortVideoDetails",
       query:{
        isAcg: 1,
       }
      })
      break;
     case 4:
     case 1:
      store.commit("pageRefresh/SET_REFRESHFLAG", {
       name: "novelDetailsPage",
       flag: false,
      });
      //小说/漫画
      this.$router.push({
       path: "/novelDetailsPage",
       query: {
        id: item.id,
        kind: kind,
        price: item.price,
       },
      });
      break;
     case 5:
      var list = JSON.stringify(this.videoList);
      //图集
      this.$router.push({
       path: "/portrayPage",
       query: {
        id: item.id,
        kind: item.kind,
        list: this.encodeBase64(list),
        phoneIndex: index,
       },
      });
      break;
     case 7:
      try {
       // 获取历史记录的最大子集信息
       let historyPostInfo = await queryHistoryId(item.id) || 0;
       // 跳转到有声小说详情页
       this.$router.push({
        path: "/audioBookDetails",
        query: {
         id: item.id,
         kind: item.kind,
         price: item.price,
         itemIndex: historyPostInfo.historyIndex || 0,
        },
       });
      } catch (error) {
       console.error(error);
      }
      break;
    }
   } else {
    if (item.newsType === 'COVER') {
     this.$router.push({
      path: '/galleryDetails',
      query: {
       vid: item.id,
      },
     });
    } else {
     if (item.playTime > 300) {
      this.$router.push({
       path: "/videoDetailsPage",
       query: {
        id: item.id,
        isAcg: 0,
       }
      })
     } else {
      this.$store.commit("video/SET_SHORT_VIDEO_LIST", this.videoList);
      this.$store.commit("video/UPDATE_SHORT_VIDEO_INDEX", index);
      this.$router.push({
       path: "/shortVideoDetails",
       query: {
        isAcg: 0,
       }
      })
     }
    }
   }
  },
  del(item) {
   this.$emit("del", item);
  },
  tagJump(item, index, itemTag) {
   let tagInfo = {
    id: item.tags[index],
    name: itemTag,
   };
   // console.log(this.$route.path)
   if (this.$route.path == "/tagList") {
    this.$router.replace({
     path: "/tagList",
     query: {
      tagData: this.encodeBase64(encodeURI(JSON.stringify(tagInfo))),
      kind: item.kind,
      t: Date.now(),
     },
    });
   } else {
    this.$router.push({
     path: "/tagList",
     query: {
      tagData: this.encodeBase64(encodeURI(JSON.stringify(tagInfo))),
      kind: item.kind,
     },
    });
   }
  },
  // 转base64
  encodeBase64(str) {
   let base64Str = Buffer.from(str, "utf-8").toString("base64");
   return base64Str;
  },
 },
};
</script>

<style lang="scss" scoped>
.verticleTwoList {
 height: 100%;
 width: 100%;

 .videoInfo {
  display: grid;
  grid-template-columns: 173px 173px;
  // margin-top: 13px;
  grid-column-gap: 9px;
  grid-row-gap: 4px;

  .videoInfoItem {
   //width: 31.5%;
   width: 100%;
   margin-bottom: 12px;
   background: #fff;
   border-radius: 8px;
   overflow: hidden;
   .advBox {
    color: #000000;
    .advImg {
     width: 100%;
     height: 98px;
     // border-radius: 6px;
     /deep/ img {
      object-fit: fill !important;
      // border-radius: 6px;
     }
    }
    .advTitle {
     margin-top: 6px;
     font-size: 12px;
     color: #000000;
     overflow: hidden;
     text-overflow: ellipsis;
     white-space: nowrap;
    }
   }

   .videoEdit {
    width: 100%;
    // width: 31.5%;
    height: 98px;
    // border-radius: 6px;
    background: rgba(0, 0, 0, 0.72);
    position: absolute;
    z-index: 10;

    img {
     width: 23px;
     height: 24px;
     position: relative;
     top: 50%;
     left: 50%;
     transform: translate(-50%, -50%);
    }
   }
   .videoItem {
    position: relative;
   }

   .videoLabel {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    text-wrap: none;
    .icon {
     width: 32px;
     height: 20px;
    }
   }

   .vipLabel {
    color: rgb(102, 61, 0);
    background-image: linear-gradient(
        to right,
        rgb(255, 231, 168),
        rgb(242, 202, 91)
    );
   }

   .beanLabel {
    font-size: 10px;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    color: rgb(255, 255, 255);
    background: rgb(255, 16, 96);
    .Golden {
     width: 14px;
     height: 14px;
    }
    // background-image: linear-gradient(
    //   to right,
    //   rgb(255, 157, 87),
    //   rgb(255, 89, 34)
    // );
   }

   .freeLabel {
    width: 30px;
    height: 16px;
    font-size: 10px;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    color: rgb(255, 255, 255);
    background-image: linear-gradient(
        to right,
        rgb(40, 249, 218),
        rgb(86, 143, 254)
    );
   }

   .previewImg {
    width: 100%;
    height: 98px;
    border-radius: 8px !important;
    background: rgba(27, 22, 76, 0.06);
    position: relative;

    // /deep/ .van-image {
    //     border-radius: 6px;
    // }

    /deep/ .van-image__img {
     border-radius: 8px;
    }
    .mask {
     position: absolute;
     bottom: 5px;
     right: 5px;
     //width: 100%;
     //height: 81px;
     //border-radius: 8px;
     //background-image: linear-gradient(
     //  to bottom,
     //  rgba(0, 0, 0, 0),
     //  rgba(0, 0, 0, 1)
     //);
     //display: flex;
     //align-items: flex-end;
     //justify-content: flex-end;
     //font-size: 8px;
     .total {
      padding: 0 9px;
      height: 20px;
      text-align: center;
      border-radius: 11px;
      background: rgba(255, 255, 255, 0.7);
      span {
       font-size: 12px;
       font-weight: 500;
       color: #3E169D;
      }
     }
     .maskText {
      margin: 0 6px 6px 0;
      color: #00ffff;
     }
     .updateEnd {
      color: #fff;
     }
    }
   }

   .videoTxt {
    margin-top: 5px;
    margin-left: 4px;
    padding-bottom: 5px;

    .videoTxtTitle {
     white-space: nowrap;
     overflow: hidden;
     text-overflow: ellipsis;
     color: #000;
     font-size: 12px;
     font-weight: 600;
    }

    .videoTxtTag {
     margin-top: 4px;
     font-size: 10px;
     font-weight: 400;
     color: #999;
     margin-right: 6px;
    }
    .activeTag {
     color: rgba(255, 95, 102, 1);
    }
   }
  }
 }
}
</style>
