<template>
  <div class="vaultList">
    <PullRefresh
        :disabled="isRefreshDisable"
        v-if="!isLoading"
        :loading="loading"
        :refreshing="refreshing"
        :finished="finished"
        @onLoad="onLoad"
        @onRefresh="onRefresh"
        :isNoData="isNoData"
        :error="error"
        :is-higeht-max="true"
        :hasAdvPagination="false"
    >
      <div class="rowsBox">
<!--        <HorizontalVideoBox-->
<!--            class="videoBox"-->
<!--            :videoInfo="item"-->
<!--            :videoList="videoList"-->
<!--            v-for="item in videoList"-->
<!--            :key="item.id"-->
<!--            :isAcg="false"-->
<!--        />-->
        <HorizontalVideoBox
            class="videoBox"
            :videoInfo="item"
            :isAcg="false"
            :currentIndex="index"
            :videoList="videoList"
            v-for="(item,index) in videoList"
            :key="item.id"
        />
      </div>
    </PullRefresh>
    <Loading v-else />
  </div>
</template>
<script>
import PullRefresh from "@/components/PullRefresh/index.vue";
import {querySubModuleList} from "@/api/app";
import {AdType, getAdItem, jumpAdv} from "@/utils/getConfig";
import HorizontalVideoBox from "@/components/HorizontalVideoBox/index.vue";
import NoData from "@/components/NoData/index.vue";
import AdvSwiper from "@/components/AdvSwiper/index.vue";
import OricilegeInfo from "@/views/movies/widget/oricilegeInfo.vue";
import Loading from "@/components/Loading/index.vue";
import {queryLibrarySearchList} from "@/api/video";
// import {List, NoticeBar, PullRefresh} from 'vant';

export default {
  name: "VaultList",
  props: {
    keyword:{
      type: Object
    }
  },
  components:{
    Loading,
    // [List.name]: List,
    // [PullRefresh.name]: PullRefresh,
    // NoData,
    HorizontalVideoBox,
    PullRefresh,
  },
  data(){
    return {
      pageNumber: 1,
      pageSize: 10,
      isRefreshDisable: false,
      isLoading: true,
      loading: true,
      refreshing: false,
      finished: false,
      isNoData: false,
      error: false,
      videoList: [],
    }
  },
  mounted(){
    this.reload();
  },
  methods:{
    goAdv(item) {
      jumpAdv(item);
    },
    reload(){
      this.isLoading = true;
      this.isNoData = false;
      this.finished = false;
      this.querySubModuleList('refresh');
    },
    onLoad() {
      this.loading = true;
      if (this.error) {
        this.error = false;
      } else {
        this.pageNumber++;
      }
      this.querySubModuleList();
    },
    onRefresh() {
      this.pageNumber = 1;
      this.videoList = [];
      this.refreshing = true;
      this.querySubModuleList('refresh');
    },
    async querySubModuleList(type) {
      let req = {
        pageNumber: this.pageNumber,
        pageSize: this.pageSize,
        keyword: this.keyword,
      };
      let res = await this.$Api(queryLibrarySearchList, req);
      this.loading = false;
      this.refreshing = false;
      this.isLoading = false;
      const list = res.data.list || [];
      if (type === "refresh") {
        this.videoList = list;
      } else {
        this.videoList = this.videoList.concat(list);
      }
      if (this.pageNumber === 1 && this.videoList.length === 0) {
        this.isNoData = true;
      }
      if (!res.data.hasNext) {
        this.finished = true;
      }
    },
  }
}
</script>
<style scoped lang="scss">
.vaultList{
  height: 100%;

  overflow-y: auto;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;

  .swiperBox {

    .swipe {
      width: 100vw;
      height: calc(100vw / 2.4);

      .van-swipe-item {
        width: 100%;
        color: #fff;
        font-size: 20px;
        line-height: 150px;
        text-align: center;
        background-color: rgb(43, 39, 72);
      }

      /deep/ .van-swipe__indicators {
        left: auto;
        right: 0;
        align-items: center;
      }

      /deep/ .van-swipe__indicator {
        width: 6px;
        height: 6px;
        background-color: rgba(148, 214, 218, 0.3);
      }

      /deep/ .van-swipe__indicator--active {
        width: 9px;
        height: 9px;
        background-color: #94d6da !important;
      }

      /deep/ img {
        object-fit: fill !important;
      }

      /deep/ .van-image__error{
        border-radius: 0;
      }
    }
  }

  .barTopic {
    width: 100%;
    display: flex;
    flex-wrap: nowrap;
    overflow-x: auto;
    // justify-content: space-between;
    //padding: 12px 0;
    padding: 0 10px;
    margin-top: 20px;
    font-size: 10px;
    color: #000000;
    text-align: center;
    //border-bottom: solid rgb(230, 230, 230) 1px;
    box-sizing: border-box;
    overscroll-behavior: contain;

    .barTopicItem {
      //width: 86px;
      flex: none;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin-right: 24px;
    }

    .barTopicItem:last-child {
      padding-right: 30px;
    }

    .topicImg {
      width: 50px;
      height: 50px;
      margin-bottom: 16px;
    }

    img {
      width: 42px;
      height: 42px;
    }

  }

  .rowsBox {
    // margin-top: 12px;
    padding: 0 10px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    .videoBox {
      width: 174px;
      margin-top: 12px;
    }
  }
}

/deep/ .horizontalVideoBox .coverBox {
  width: 173px;

  .cover{
    height: 98px;
  }
}
/deep/ .videoTitle {
  padding-bottom: 0 !important;
}
</style>
